
export default {
  name: "Promotion",
  data() {
    return {
      lang: "th",
      numPromotion: 0,
      promotionAvailable: false,
      popup: true,
      title: null,
      img: null,
      info: null,
      card: null,
      label: null,
      btn_label: null,
    };
  },
  watch: {
    $route(newValue) {
      this.lang = newValue.params.lang;
      this.getPromotion();
    },
  },
  methods: {
    async getPromotion() {
      const { results } = await this.$prismic.client.query([
        this.$prismic.Predicates.at("document.type", "promotion"),
        this.$prismic.Predicates.at("document.tags", [this.lang]),
      ]);
      this.card = results[0].data.card;
      this.title = results[0].data.title[0].text;
      this.label = results[0].data.label[0].text;
      this.btn_label = results[0].data.btn[0].text;
      this.numPromotion = results[0].data.card.length;
      if (this.numPromotion !== 0) {
        this.promotionAvailable = true;
      } else {
        this.promotionAvailable = false;
      }
    },
    popupClickHandler() {
      this.$router.push(`/${this.lang}/promotion/`);
      this.popup = false;
    },
  },
};
