
import PopUp from './components/PopUp.vue'

export default {
  data() {
    return {};
  },
  components: { PopUp },
  mounted() {
    this.$gtag.pageview(this.$route);
  },
  watch: {},
};
