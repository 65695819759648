import Vue from 'vue'
import VueMeta from 'vue-meta'
import App from './App.vue'
import router from './router'
import VueLazyLoad from 'vue-lazyload'
import LoadScript from 'vue-plugin-load-script'
import SweetAlertIcons from 'vue-sweetalert-icons'
import PrismicVue from '@prismicio/vue'
import './styles/th-font.css'
import './styles/en-font.css'
import './quasar'
import VueFbCustomerChat from 'vue-fb-customer-chat'
import VueGtag from "vue-gtag";

Vue.use(VueMeta);

Vue.use(VueGtag, {
  config: { id: "UA-185962572-1" }
});

Vue.use(VueFbCustomerChat, {
  page_id: 244779485907271, //  change 'null' to your Facebook Page ID,
  theme_color: '#836d4d', // theme color in HEX
  locale: 'en_US', // default 'en_US'
})


const accessToken = '' // Leave empty if your repo is public
const endpoint = 'https://kaewsamui.cdn.prismic.io/api/v2'
Vue.use(PrismicVue, {
    endpoint,
    apiOptions: { accessToken }
  })



Vue.use(SweetAlertIcons)
Vue.use(LoadScript)
require('vue-image-lightbox/dist/vue-image-lightbox.min.css')
Vue.use(VueLazyLoad)
Vue.config.productionTip = false
Vue.prototype.$language = 'EN'

new Vue({
    router,
    render: h => h(App)
}).$mount('#app')