import Vue from "vue";
import VueRouter from "vue-router";
import VueMeta from 'vue-meta'

Vue.use(VueRouter);
Vue.use(VueMeta)

const routes = [
  {
    path: "/:lang",
    name: "Home",
    components: {
      default: () => import("../views/Home.vue"),
      header: () => import("../components/Toolbar"),
      footer: () => import("../components/Footer"),
    },
  },
  {
    path: "/:lang/accommodation",
    name: "Accommodation",
    components: {
      default: () => import("../views/Accommodation.vue"),
      header: () => import("../components/Toolbar"),
      footer: () => import("../components/Footer"),
    },
  },
  {
    path: "/:lang/food",
    name: "Food",
    components: {
      default: () => import("../views/Food.vue"),
      header: () => import("../components/Toolbar"),
      footer: () => import("../components/Footer"),
    },
  },
  {
    path: "/:lang/facilities",
    name: "Facilities",
    components: {
      default: () => import("../views/Facilities.vue"),
      header: () => import("../components/Toolbar"),
      footer: () => import("../components/Footer"),
    },
  },
  {
    path: "/:lang/contact",
    name: "Contact",
    components: {
      default: () => import("../views/Contact.vue"),
      header: () => import("../components/Toolbar"),
      footer: () => import("../components/Footer"),
    },
  },
  {
    path: "/:lang/wedding",
    name: "Wedding",
    components: {
      default: () => import("../views/Wedding.vue"),
      header: () => import("../components/Toolbar"),
      footer: () => import("../components/Footer"),
    },
  },
  {
    path: "/:lang/accommodation/:name",
    name: "Sub Accommodation",
    components: {
      default: () => import("../views/RoomPage.vue"),
      header: () => import("../components/Toolbar"),
      footer: () => import("../components/Footer"),
    },
  },
  {
    path: "/:lang/facilities/:name",
    name: "Sub Facilities",
    components: {
      default: () => import("../views/MeetingPage.vue"),
      header: () => import("../components/Toolbar"),
      footer: () => import("../components/Footer"),
    },
  },
  {
    path: "/:lang/promotion/",
    name: "Promotion",
    components: {
      default: () => import("../views/Promotion.vue"),
      header: () => import("../components/Toolbar"),
      footer: () => import("../components/Footer"),
    },
  },
  {
    path: "/:lang/durability/",
    name: "Durability",
    components: {
      default: () => import("../views/Durability.vue"),
      header: () => import("../components/Toolbar"),
      footer: () => import("../components/Footer"),
    },
  },
  {
    path: "*",
    name: "PageNotFound",
    components: {
      default: () => import("../views/Home.vue"),
      header: () => import("../components/Toolbar"),
      footer: () => import("../components/Footer"),
    },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
});

router.beforeEach((to, from, next) => {
  const { lang } = to.params;
  if (!lang) {
    next({ name: "Home", params: { lang: "th" } });
  }
  next();
});

export default router;
